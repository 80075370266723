/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */


/* Browser Resets
*********************************/
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus  {outline: none;}
.slides,
.flex-control-nav,
.flex-direction-nav {margin: 0; padding: 0; list-style: none;}

/* Icon Fonts
*********************************/
/* Font-face Icons */
@font-face {
	font-family: 'flexslider-icon';
	src:url('fonts/flexslider-icon.eot');
	src:url('fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'),
		url('fonts/flexslider-icon.woff') format('woff'),
		url('fonts/flexslider-icon.ttf') format('truetype'),
		url('fonts/flexslider-icon.svg#flexslider-icon') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* FlexSlider Necessary Styles
*********************************/
.flexslider {margin: 0; padding: 0;}
.flexslider .slides > li {display: none; -webkit-backface-visibility: hidden;} /* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {width: 100%; display: block;}
.flex-pauseplay span {text-transform: capitalize;}

/* Clearfix for the .slides element */
.slides:after {content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;}
html[xmlns] .slides {display: block;}
* html .slides {height: 1%;}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {display: block;}

/* FlexSlider Default Theme
*********************************/
.flexslider { 
    margin: 0 0 60px; 
    background: #fff; 
    /*border: 4px solid #fff;*/ 
    position: relative; 
    /*-webkit-border-radius: 4px; -moz-border-radius: 4px; -o-border-radius: 4px;*/ 
    /*border-radius: 4px;*/ 
    /*-webkit-box-shadow: 0 1px 4px rgba(0,0,0,.2); -moz-box-shadow: 0 1px 4px rgba(0,0,0,.2); -o-box-shadow: 0 1px 4px rgba(0,0,0,.2);*/ 
    /*box-shadow: 0 1px 4px rgba(0,0,0,.2);*/ 
    zoom: 1; }
.flex-viewport { max-height: 2000px; -webkit-transition: all 1s ease; -moz-transition: all 1s ease; -o-transition: all 1s ease; transition: all 1s ease; }
.loading .flex-viewport { max-height: 300px; }
.flexslider .slides { zoom: 1; }
.carousel li { margin-right: 5px; }

/* Direction Nav */
.flex-direction-nav {*height: 0;}
.flex-direction-nav a  { 
    text-decoration:none; 
    display: block; 
/*    width: 40px; 
    height: 40px; */
    
    width: 48px;
    height: 48px;
    
    background: url(/templates/esperanto/img/slider-left-arrow.png) no-repeat top left;
    background-size: 48px;
    
    margin: -20px 0 0; 
    position: absolute; 
    top: 50%; 
    
    z-index: 147; 
    
    overflow: hidden; 
    opacity: 0; 
    cursor: pointer; 
    color: rgba(0,0,0,0.8); 
    text-shadow: 1px 1px 0 rgba(255,255,255,0.3); 
    -webkit-transition: all .3s ease; 
    -moz-transition: all .3s ease; 
    transition: all .3s ease; 
}
.flex-direction-nav .flex-prev { 
    /*left: -75px;*/ 
    left: -630px;
}
.flex-direction-nav .flex-next { 
    /*right: -75px;*/
    right: -628px;
    
    text-align: right; 
    background: url(/templates/esperanto/img/slider-right-arrow.png) no-repeat top left;
    background-size: 48px;
}
.flexslider:hover .flex-prev, .flexslider-outer:hover .flex-prev { 
    /*opacity: 0.7;*/ 
    opacity: 1; 
            /*left: 10px;*/ 
}
.flexslider:hover .flex-next, .flexslider-outer:hover .flex-next { 
    /*opacity: 0.7;*/ 
    opacity: 1; 
            /*right: 10px;*/ 
}



.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover { opacity: 1; }
.flexslider-outer:hover .flex-next:hover, .flexslider-outer:hover .flex-prev:hover { opacity: 1; }


.flex-direction-nav .flex-disabled { opacity: 0!important; filter:alpha(opacity=0); cursor: default; }



/*.flex-direction-nav a:before  { 
    font-family: "flexslider-icon"; font-size: 40px; line-height:1; display: inline-block; 
    
    width: 25px;
    height: 25px;
    
    content: '\f001'; 
    
    background: url(/templates/esperanto/img/slider-left-arrow.png) no-repeat top left;
    background-size: 25px;
}
.flex-direction-nav a.flex-next:before  { 
    content: '\f002'; 
    background: url(/templates/esperanto/img/slider-right-arrow.png) no-repeat top left;
    background-size: 25px;
}*/

/* Pause/Play */
.flex-pauseplay a { display: block; width: 20px; height: 20px; position: absolute; bottom: 5px; left: 10px; opacity: 0.8; z-index: 10; overflow: hidden; cursor: pointer; color: #000; }
.flex-pauseplay a:before  { font-family: "flexslider-icon"; font-size: 20px; display: inline-block; content: '\f004'; }
.flex-pauseplay a:hover  { opacity: 1; }
.flex-pauseplay a.flex-play:before { content: '\f003'; }

/* Control Nav */
.flex-control-nav {width: 100%; position: absolute; bottom: -40px; text-align: center;}
.flex-control-nav li {margin: 0 6px; display: inline-block; zoom: 1; *display: inline;}
.flex-control-paging li a {width: 11px; height: 11px; display: block; background: #666; background: rgba(0,0,0,0.5); cursor: pointer; text-indent: -9999px; -webkit-border-radius: 20px; -moz-border-radius: 20px; -o-border-radius: 20px; border-radius: 20px; -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3); -moz-box-shadow: inset 0 0 3px rgba(0,0,0,0.3); -o-box-shadow: inset 0 0 3px rgba(0,0,0,0.3); box-shadow: inset 0 0 3px rgba(0,0,0,0.3); }
.flex-control-paging li a:hover { background: #333; background: rgba(0,0,0,0.7); }
.flex-control-paging li a.flex-active { background: #000; background: rgba(0,0,0,0.9); cursor: default; }

.flex-control-thumbs {margin: 5px 0 0; position: static; overflow: hidden;}
.flex-control-thumbs li {width: 25%; float: left; margin: 0;}
.flex-control-thumbs img {width: 100%; display: block; opacity: .7; cursor: pointer;}
.flex-control-thumbs img:hover {opacity: 1;}
.flex-control-thumbs .flex-active {opacity: 1; cursor: default;}


/*ml*/
.flexslider {
    /*overflow: visible;*/
    height: 350px;
}
.flexslider .flex-viewport {
    overflow: visible!important;
    height: 350px;
}
.flexslider .slides li.parent {
    /*opacity: 0.5;*/
    position: relative;
    height: 350px;
    box-sizing: border-box;
}
.flexslider .slides li.parent li {
    margin-bottom: 15px;
    list-style-image: url(/templates/esperanto/img/li_15_15_white.png);
    display: list-item;
}
.flexslider .slides li.flex-active-slide {
  opacity: 1;  
}
.flexslider .slides {
    height: 350px;
    box-sizing: border-box;
}


.flexslider .slides li a{
    width: 645px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 130;
    box-sizing: border-box;
    background: url(/templates/esperanto/img/opa.png) repeat top left;
}

.flexslider .slides li .opa-bg {
    width: 645px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 150;
    box-sizing: border-box;
    background: rgba(38,130,205,0.5);
}
.flexslider .slides li.flex-active-slide .opa-bg  {
    display: none;
}
.flexslider .slides li img.big-img{
    width: 645px;
    height: 350px;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 120;
    box-sizing: border-box;
}
.flexslider .slides li img.ico-img {
    width: auto;
    margin-right: 15px;
    float: left;
    margin-top: 17px;
}
.flexslider .slides li.parent .slider-text-01{
    width: 455px;
    padding: 0px 25px;
    background-color: #0860a8;
    color: #FFFFFF;
    height: 58px;
    line-height: 58px;
    position: absolute;
    left: 0px;;
    top: 0px;
    font-size: 20px;
    z-index: 125;
    box-sizing: border-box;
    
}
.flexslider .slides li.parent .slider-text-02{
    width: 455px;
    padding: 23px 37px;
    background-color: #2682cd;
    color: #FFFFFF;
    opacity: 1!important;
    height: 292px;
    position: absolute;
    left: 0px;
    top: 58px;
    font-size: 17px;
    z-index: 125;
    box-sizing: border-box;
}
.flexslider .slides li.parent .slider-text-02 p{
    margin-bottom: 15px;
}
.flexslider .slides li.parent .slider-text-02 ul {
    margin: 0;
}
.flexslider .slides li.parent .slider-text-03{
    width: 645px;
    padding: 16px 20px;
    background-color: rgba(0,0,0,0.4);
    color: #FFFFFF;
    height: 88px;
    text-align: right;
    font-size: 30px;
    line-height: 1;
    font-family: arial;
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 125;
    box-sizing: border-box;
}
.flexslider .flex-direction-nav {
    height: 350px;
    position: absolute;
    top: 0px;
    /*width: 100%;*/
    /*left: 0px;*/
    
    width: 2px;
    left: 50%;
    
}
#new-horizont-slider {
    width: 100%;
    height: 350px;
    overflow: hidden;
}
.flexslider-outer {
    position: relative;
    width: 100%;
    height: 100%;
}
.opa-bg-left, .opa-bg-right {
    position: absolute;
    width: 1000px;
    height: 100%;
    background: rgba(38,130,205,0.5);
    top: 0px;
    z-index: 145;
}
.opa-bg-left {
    left: -1000px;
}
.opa-bg-right {
    right: -1000px;
}
.flex-control-nav {
    display: none;
}


@media screen and (max-width: 1040px) {
    //.flex-direction-nav .flex-prev { opacity: 1; left: -552px;}
    .flex-direction-nav .flex-prev {
        display: none;
        opacity: 0; left: -485px;}
    //.flex-direction-nav .flex-next { opacity: 1; right: -550px;}
    .flex-direction-nav .flex-next {
        display: none;
        opacity: 0; right: -485px;}
    .flexslider .slides li.parent{

        .slider-text-01, .slider-text-02 {
            z-index: 130;
            width: 40%;
        }
        .slider-text-01 {
            span + span {
                //white-space: nowrap;
                //overflow: hidden;
                //text-overflow: ellipsis;
            }
        }
    }

}
@media screen and (max-width: 991px) {
    .flexslider {
        .slides {
            li.parent {
                li, .slider-text-02 p {
                    font-size: 75%;
                }
                .slider-text-01 {
                    font-size: 15px;
                }
                .slider-text-03 {
                    font-size: 20px;
                }
            }
        }
    }
}
@media screen and (max-width: 860px) {
    .flex-direction-nav .flex-prev {
        opacity: 0; left: -382px;
        display: none;
    }
    .flex-direction-nav .flex-next {
        opacity: 0; right: -380px;
        display: none;
    }
}

@media only screen and (max-width: 570px) {
    #new-horizont-slider {
        height: 318px;
        overflow: hidden;
    }
    .flexslider {
        height: 328px;

        .slides {
            height: 328px;

            li.parent {
                height: 328px;

                .slider-text-01, .slider-text-02 {
                    width: 100%;
                    height: 58px;
                }
                .slider-text-02 {
                    height: 270px;
                }
            }
        }
    }

}